import React from 'react'

import { Header } from './header'
import { Footer } from './footer'

interface LayoutProps {
  location: typeof window.location
}

export const Layout: React.FC<LayoutProps> = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div data-is-root-path={isRootPath}>
      <Header
        alwaysSticky={isRootPath}
        location={location}
      />
      <main>{children}</main>
      <Footer location={location} />
    </div>
  )
}