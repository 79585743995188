/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import favicon from '../images/icon.jpeg'

interface SEOProps {
  description?: string
  lang?: string
  meta?: any[]
  title?: string
}

export const SEO: React.FC<SEOProps> = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title
    ? `${title} | ${site.siteMetadata.title}`
    : site.siteMetadata.title

  return (
    // @ts-ignore
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      link={[
        {
          rel: `shortcut icon`,
          href: favicon,
          type: `image/png`,
        },
        {
          rel: `stylesheet`,
          href: `https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap`,
        },
        {
          rel: `stylesheet`,
          href: `https://fonts.googleapis.com/css2?family=Merriweather:wght@400;500;700&display=swap`,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta || [])}
    />
  )
}

SEO.defaultProps = {
  lang: `pt-BR`,
  meta: [],
  description: ``,
}
