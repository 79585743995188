import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FiMapPin } from 'react-icons/fi'
import {
  faEnvelope,
  faUser,
  faPhone,
  faSpinner,
  faMap,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'
import clsx from 'clsx'
import axios from 'axios'
import { useBoolean, useInput } from 'react-hanger'

import * as styles from './footer.module.scss'
import logo from '../../images/logo-colorido.svg'
import contact from '../../images/contact.png'

const contactFormUrl = `https://api.roberty.app/prod/1/website/createBitrixLead`

const contacts = [
  {
    href: `https://api.whatsapp.com/send?phone=5516993982007`,
    text: `(16) 99398-2007`,
    icon: faWhatsapp,
  },
  {
    href: `mailto:contato@highjump.com.br`,
    text: `contato@highjump.com.br`,
    icon: faEnvelope,
  },
]

interface FooterProps {
  location: Location
}

export const Footer: React.FC<FooterProps> = ({ location }) => {
  const name = useInput(``)
  const phone = useInput(``)
  const email = useInput(``)
  const message = useInput(``)
  const loading = useBoolean(false)

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      loading.setTrue()
      try {
        await axios.post(contactFormUrl, {
          title: `[Blog] - ${name.value}`,
          name: name.value,
          phone: phone.value,
          email: email.value,
          description: `${message.value} \n\n [Origem: ${location.href}]`,
        })
        name.clear()
        phone.clear()
        email.clear()
        message.clear()
        alert(
          `Sua mensagem foi enviada com sucesso! Aguarde, em breve entraremos em contato com você.`
        )
      } catch (error) {
        console.error(error)
        alert(
          `Houve um erro ao enviar sua mensagem. Por favor, tente novamente mais tarde.`
        )
      } finally {
        loading.setFalse()
      }
    },
    [name.value, phone.value, email.value, message.value]
  )

  return (
    <footer className={styles.footer}>
      <div className={styles.pointersBg}></div>

      <div className="row align-items-center">
        <div className="col-12 col-lg-4">
          <div className={styles.imageContent}>
            <img src={contact} alt="contato" />
          </div>
          <div className={styles.location}>
                  <h3>Onde estamos</h3>
                  <br />
                  <p>
                    <b>Ribeirão Preto, SP</b>
                    <br />
                    R. Gen. Augusto Soares dos Santos, 100
                    <br />
                    Parque Industrial Lagoinha - Sala 402
                  </p>
                  <a
                    href="https://goo.gl/maps/sHftt2T4NsxH7TJe7"
                    target={'_blank'}
                  >
                    <FiMapPin /> Ver no mapa
                  </a>
                  <br />
                  <br />
                  <p>
                    <b>São Paulo, SP</b>
                    <br />
                    Rua Doutor Renato Paes de Barros, 618
                    <br />
                    Itaim Bibi - Campus 1, CJ 01
                  </p>
                  <a
                    href="https://goo.gl/maps/hvh3V5noNvtvxDfK7"
                    target={'_blank'}
                  >
                    <FiMapPin /> Ver no mapa
                  </a>
                </div>
        </div>

        <div className="col-12 col-lg-8">
          <div className={styles.formContent}>
            <div className={styles.title}>
              <h3>
                Pronto para
                <br /> o grande <span>salto?</span>
                <br /> Vamos conversar.
              </h3>
            </div>
            <form
              id="contato"
              onSubmitCapture={handleSubmit}
              autoComplete="off"
              role="presentation"
            >
              <div className={clsx(styles.inputGroup)}>
                <span>
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <input
                  name="name"
                  type="text"
                  placeholder="Seu nome"
                  required={true}
                  value={name.value}
                  onChange={name.onChange}
                  disabled={loading.value}
                />
              </div>

              <div className={clsx(styles.inputGroup)}>
                <span>
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <input
                  name="phone"
                  type="tel"
                  placeholder="Telefone (com WhatsApp)"
                  required={true}
                  value={phone.value}
                  onChange={phone.onChange}
                  disabled={loading.value}
                />
              </div>

              <div className={clsx(styles.inputGroup)}>
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <input
                  name="email"
                  type="email"
                  placeholder="Seu melhor e-mail"
                  value={email.value}
                  onChange={email.onChange}
                  disabled={loading.value}
                />
              </div>

              <div className={clsx(styles.inputGroup)}>
                <textarea
                  name="message"
                  placeholder="Como podemos ajudar?"
                  required={true}
                  value={message.value}
                  onChange={message.onChange}
                  disabled={loading.value}
                />
              </div>

              <button type="submit" disabled={loading.value}>
                {loading.value ? (
                  <>
                    Enviando
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </>
                ) : (
                  <>Enviar</>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className={styles.footerInfo}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={styles.links}>
          <ul>
            {contacts.map(({ href, text, icon }) => (
              <li key={href}>
                <a href={href}>
                  <FontAwesomeIcon icon={icon} /> {text}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.social}>
          <a
            href="https://www.instagram.com/highjumpconsulting/"
            target={`_blank`}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://www.linkedin.com/company/high-jump-consulting/"
            target={`_blank`}
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
        <div className={styles.copy}>
          <p>
            <span>© 2022 Highjump Consulting</span>
            <span>Todos os direitos reservados</span>
          </p>
          <a
            href="https://highjump.com.br/politica-de-privacidade"
            target={`_blank`}
          >
            Política de Privacidade
          </a>
        </div>
      </div>
    </footer>
  )
}
