// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--lU7l1";
export var pointersBg = "footer-module--pointersBg--+GEpp";
export var contactItem = "footer-module--contactItem--8geQw";
export var contactIcon = "footer-module--contactIcon--txdw3";
export var imageContent = "footer-module--imageContent--kjJ4e";
export var location = "footer-module--location--IpWaw";
export var formContent = "footer-module--formContent--6WOsk";
export var title = "footer-module--title--u4wuF";
export var inputGroup = "footer-module--inputGroup--iEXDC";
export var footerInfo = "footer-module--footerInfo--RyzVU";
export var logo = "footer-module--logo--5nyW8";
export var links = "footer-module--links--83gnD";
export var social = "footer-module--social--AXYhi";
export var copy = "footer-module--copy--HGmxn";