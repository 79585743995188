import React from 'react'
import clsx from 'clsx'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import * as styles from './header.module.scss'
import logo from '../../images/logo-colorido.svg'
import logoWhite from '../../images/logo.svg'

const items = [
  {
    text: `highjump`,
    sameOrigin: false,
    href: `https://highjump.com.br/`,
  },
  {
    text: `soluções`,
    sameOrigin: false,
    href: `https://highjump.com.br/#solutions`,
  },
  {
    text: `cases`,
    sameOrigin: false,
    href: `https://highjump.com.br/#cases`,
  },
  {
    text: `manifesto`,
    sameOrigin: false,
    href: `https://highjump.com.br/#manifest`,
  },
  {
    text: `blog`,
    sameOrigin: true,
    href: `/blog/`,
  },
]

interface HeaderProps {
  alwaysSticky?: boolean
  location: Location
}

export const Header: React.FC<HeaderProps> = ({ alwaysSticky, location }) => {
  const onContactClick = () => {
    const elem: HTMLInputElement = document.querySelector(
      `form#contato input[name="name"]`
    )!
    elem.focus()
  }

  return (
    <header
      className={clsx({
        [styles.header]: true,
        [styles.isHome]: location.pathname.includes(`/blog`),
      })}
    >
      <div className="container">
        <div
          className={clsx([
            `row`,
            `justify-content-center`,
            `justify-content-md-between`,
            `align-items-center`,
          ])}
        >
          <div className="col-auto">
            <AnchorLink to="/">
              <img className={styles.logo} src={logo} alt="High Jump" />
              <img
                className={styles.logoWhite}
                src={logoWhite}
                alt="High Jump"
              />
            </AnchorLink>
          </div>

          <div className="col-auto">
            <nav className="main-menu">
              {items.map((item, index) =>
                item.sameOrigin ? (
                  <AnchorLink
                    key={index}
                    className={clsx([
                      styles.menuItem,
                      `d-none`,
                      `d-lg-inline-block`,
                    ])}
                    to={item.href}
                  >
                    {item.text}
                  </AnchorLink>
                ) : (
                  <a
                    key={index}
                    className={clsx([
                      styles.menuItem,
                      `d-none`,
                      `d-lg-inline-block`,
                    ])}
                    href={item.href}
                    target="_blank"
                    rel="noopener"
                  >
                    {item.text}
                  </a>
                )
              )}

              <AnchorLink
                to={location.pathname + `#contato`}
                onAnchorLinkClick={onContactClick}
                className={clsx([styles.cta, `d-lg-inline-block`])}
              >
                fale já com especialista
              </AnchorLink>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  alwaysSticky: false,
}
